/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Route, Routes } from 'react-router-dom'
import './App.css';
import axios from 'axios'

axios.interceptors.request.use(config => {
  const token = window.localStorage.getItem('token');
  if (token) {
    config.headers.common['Authorization'] = 'Bearer ' + token;
  }
  return config;
});

axios.interceptors.response.use(data => {
  if (data.code && data.code === 401) {
    window.localStorage.removeItem('token');
    return;
  }
  return data;
}, err => {
  if (err.response && err.response.status && err.response.status === 401) {
    window.localStorage.removeItem('token');
  }
  return Promise.resolve(err);
})


function App() {
  
  return (
    <Routes>
      <Route exact path="/" element={
        <div>
          <span className='Content'>人生如逆旅 我亦是行人</span>

          <span className='Footer'><a href='https://beian.miit.gov.cn/'>蜀ICP备17039084号-1</a></span>
          <br/>
        </div>
        } />
    </Routes>
  );
}

export default App;
